import * as React from "react";
import { Container } from "react-bootstrap";

export const Events: React.FC = () => {
  return (
    <Container>
      <div id="cemboo-stream-library"></div>
    </Container>
  );
};
