import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { Navbar } from "./components";
import { Events, Home } from "./container";

export const App: React.FC = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
      </Routes>
    </>
  );
};
