import * as React from "react";
import { Container } from "react-bootstrap";

import * as Styled from "./HeroSection.styled";

export const HeroSection: React.FC<{ onWatchNowClick: () => void }> = ({
  onWatchNowClick,
}) => {
  return (
    <Styled.Header>
      <Styled.Overlay></Styled.Overlay>
      <Styled.Video
        src={process.env.REACT_APP_HERO_VIDEO_URL!}
        autoPlay={true}
        loop
        muted
      />
      <Container>
        <Styled.HeroContent>
          <Styled.Title></Styled.Title>
          <Styled.Description>
            </Styled.Description>
          {/* <Button variant="danger" href="/" style={{ width: "50%" }}>
            Watch Now
          </Button> */}
        </Styled.HeroContent>
      </Container>
      <Container>
        <Styled.ScrollToEvents onClick={onWatchNowClick}>
          <i className="fas fa-chevron-circle-down"></i>
        </Styled.ScrollToEvents>
      </Container>
    </Styled.Header>
  );
};
