import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const EventsContainer = styled(Container)`
  min-height: 400px;
  margin-top: 50px;
  margin-bottom: 100px;
`;

export const EventsTabContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  margin: 0;
  margin-left: 30px;
  border-bottom: 1px solid #ccc;

  @media screen and (min-width: 768px) {
    padding: 0px;
    width: 100%;
  }
`;

export const EventsTab = styled.span`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
  &.active {
    border-bottom: 3px solid #dc3545;
  }

  &:hover {
    border-bottom: 3px solid #111;
  }
`;

export const TabsContentContainer = styled(Container)`
  margin-top: 20px;
`;

export const EventCardRow = styled(Row)`
  margin-top: 40px;
`;

export const EventCardCol = styled(Col)`
  margin-bottom: 50px;
`;
