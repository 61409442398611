import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  height: 90vh;
  position: relative;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const HeroContent = styled.div`
  position: absolute;
  width: 25%;
  height: 100%;
  display: flex;
  top: 0;
  flex-direction: column;

  justify-content: center;
  color: #fff;

  @media only screen and (max-width: 1459px) and (min-width: 1060px) {
    width: 35%;
  }
  @media screen and (max-width: 1059px) and (min-width: 940px) {
    width: 40%;
  }

  @media screen and (max-width: 939px) and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) and (min-width: 600px) {
    width: 60%;
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    left: 0;
    text-align: center;
    align-items: center;
  }
`;

export const Title = styled.h3`
  font-size: 48px;
`;

export const Description = styled.p`
  width: 100%;
`;

export const ScrollToEvents = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 140px;
  left: 50%;
  transform: translateX(-50px);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 5px;
  cursor: pointer;
`;
