import styled from "styled-components";

export const EventCard = styled.div`
  height: 240px;
  width: 90%;
  margin: 0 auto;
  border: 1px solid transparent;
  border-radius: 5px;

  &:hover {
    box-shadow: 3px -5px 55px 0px rgba(0, 0, 0, 0.75);
  }
  cursor: pointer;
  box-shadow: 3px -5px 55px 0px #ccc;

  position: relative;
`;

export const EventCardTitleWrapper = styled.div`
  height: 70%;
  padding: 30px 20px;
  width: 100%;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 20px;
  width: 80%;
  display: inline-block;
`;

export const Type = styled.span`
  margin-top: 20px;
  background: #ccc;
  padding: 5px 20px;
  display: inline-block;

  &.private {
    background: #dc3545;
    color: #fff;
  }
`;

export const Logo = styled.img`
  width: 75px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const EventCardDetailWrapper = styled.div`
  height: 30%;
  background: #000;
  width: 100%;
  color: #fff;
  padding: 10px 20px;
`;

export const Divider = styled.div`
  height: 10px;
  background: linear-gradient(
    to right bottom,
    rgba(126, 213, 111, 0.8),
    rgba(40, 180, 133, 0.8)
  );
`;
