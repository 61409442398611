import * as React from "react";
import { Navbar as BsNavbar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

import Logo from "./../../assets/logo.png";

import * as Styled from "./Navbar.styled";

export const Navbar: React.FC = () => {
  return (
    <BsNavbar bg="dark" variant="dark" fixed="top" expand="lg">
      <Container fluid>
        <BsNavbar.Brand href="/">
          <img
            src={Logo}
            height="30"
            className="d-inline-block align-top"
            alt="All Abilities Live"
          />
        </BsNavbar.Brand>
        <BsNavbar.Toggle id="basic-navbar-nav" />
        <BsNavbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            {/* <Nav.Link as="div">
              <Styled.StyledNavLink
                to="library"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Library
              </Styled.StyledNavLink>
            </Nav.Link>

            <Nav.Link as="div">
              <Styled.StyledNavLink
                to="events"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Events
              </Styled.StyledNavLink>
            </Nav.Link> */}
            <Styled.JoinNowButton id="cembooClientJoinNowButton"></Styled.JoinNowButton>
          </Nav>
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  );
};
