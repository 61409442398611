import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const BsNavLink = styled(Nav.Link)`
    color: rgba(255, 255, 255, 0.6);

    &:hover {
        color: rgba(255, 255, 255, 1);
    }
    margin-left: 5px;
`;

export const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid transparent;
    padding: 5px;

    &.active {
        color: rgba(255, 255, 255, 1);
        border-bottom: 3px solid #dc3545;
    }

    &:hover {
        color: rgba(255, 255, 255, 1);
    }
`;

export const JoinNowButton = styled.div`
    &  button.amplify-button {
        background-color: rgba(195, 45, 51, 1) !important;
        color: #FFFFFF !important;
        border: 1px solid rgba(195, 45, 51, 1) !important;
        border-radius: 0 !important;
        transition: all 0.3s ease-out;
    }

    & button.amplify-button:hover, 
    & button.amplify-button:focus {
        background-color: rgba(195, 45, 51, 1) !important;
        color: #FFFFFF !important;
        border: 1px solid rgba(144, 32, 37, 1) !important;
        transition: all 0.3s ease-out;
    }
`;
